import React from 'react';

function Blog() {
  return (
    <div>
      <h1>Blog</h1>
      <p>Mantente al tanto de nuestras últimas noticias y eventos.</p>
    </div>
  );
}

export default Blog;
